import React, { Component } from 'react';
//import Config from '/config';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.getTokenPayment.bind(this);
    this.state = {
      token:''
    };
  }

  componentWillMount(){
    // this.getTokenPayment();
    }
getTokenPayment() {
  let API_DOMAIN;
  let X_API_KEY ;
  //let pathParam = window.location.pathname;
 // let email = pathParam.substring(1,pathParam.length);
 let email= "testtest@gmail.com"
  let callUrl = window.location.href;
  console.log(email);
  console.log("app page");

  switch (callUrl.substring(0, 11)) {
    case 'https://dev':
      API_DOMAIN = 'https://develop-api.dev.tilia-aidants.fr';
      X_API_KEY = 'qI3r9FV2hra33AkmOfWfu1fxqVaiWVoK85LVcQjy';
      break;
    case 'https://ppd':
      API_DOMAIN = 'https://ppd-api.preprod.tilia-aidants.fr';
      X_API_KEY = 'P0zsjgtZ9Q95ZhFCnPVJa5KDk6gJDp4t5o3aMyQY';
      break;
    case 'https://prd':
      API_DOMAIN = 'https://prd-api.tilia-aidants.fr';
      X_API_KEY = 'VjHVsKpnVr3MT4fhTfKdHa3ud9NOhq7t9jYX9lRL';
      break;
    default:
      API_DOMAIN = 'https://develop-api.dev.tilia-aidants.fr';
      X_API_KEY = 'qI3r9FV2hra33AkmOfWfu1fxqVaiWVoK85LVcQjy';
  }


 return fetch(API_DOMAIN+'/payment/get-payment-token', {
      method: 'POST',
      body: JSON.stringify({email:email}),
      headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': X_API_KEY
      }
  }).then(response => {
      return response.json();})
  .then(jsonRes => {
        var att = document.createAttribute("id"); 
        att.value = "PaylineWidget";   
        var att1 = document.createAttribute("data-token"); 
        att1.value = jsonRes.token;
        var att2 = document.createAttribute("data-template"); 
        att2.value = "column";
        var att3 = document.createAttribute("data-embeddedredirectionallowed"); 
        att3.value = "false";
        var element = document.createElement('div');
        element.setAttributeNode(att); 
        var elementScript = document.createElement('script');
        var att4 = document.createAttribute("src"); 
        att4.value = "https://homologation-payment.payline.com/scripts/widget-min.js";
        elementScript.setAttributeNode(att4);

        var elementLink = document.createElement('link');
        var att5 = document.createAttribute("href"); 
        att5.value = "https://homologation-payment.payline.com/styles/widget-min.css";
        elementLink.setAttributeNode(att5);

        var att6 = document.createAttribute("rel"); 
        att6.value = "stylesheet";
        elementLink.setAttributeNode(att6);
        element.setAttributeNode(att); 
        element.setAttributeNode(att1); 
        element.setAttributeNode(att2); 
        element.setAttributeNode(att3); 
        document.getElementById("body").appendChild(elementScript);
        document.getElementById("body").appendChild(elementLink);
        document.getElementById("body").appendChild(element);
  })
  .catch(err => {console.log(err);alert('une erreur est survenue.'); return err});
}
  render() {

    return (
          <div>
          </div> 
    );
  }
}

export default App;
